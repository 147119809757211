import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
//import 'uikit/dist/css/uikit.css'

import "./contest-form.scss"


const ContestForm = ({ title, subtitle, icon, tagId, allowAnswer }) => {

    const [email, setEmail] = useState("");

    const [answer, setAnswer] = useState("");


    return (
        <div className="form-content">

            <div className="form-subscribe-icon">
                <GatsbyImage className="left-img" image={icon} alt={""}></GatsbyImage>
            </div>

            <div className="form-subscribe-content">
                <b>{title}</b>
                <p>{subtitle}</p>
                <div className="centered-form-fields">

                    <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
                    <div id="mc_embed_signup">
                        <form action="https://wayfarersarcade.us18.list-manage.com/subscribe/post?u=5fd00cee0a74e2316d00a39ca&amp;id=002ab63c42&amp;f_id=002d27e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                                {/* <h2>Subscribe</h2> */}
                                {/* <div class="indicates-required"><span class="asterisk">*</span> indicates required</div> */}
                                <div class="mc-field-group">
                                    {/* <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
                                </label> */}
                                    {
                                        allowAnswer ?
                                            <input type="text" value={answer} name="ANSWER" class="required_answer" id="mce-ANSWER" onChange={(e) => setAnswer(e.target.value)} required placeholder="Your answer here" />
                                            : ""
                                    }
                                    <input type="email" value={email} name="EMAIL" className="required_email" id="mce-EMAIL" onChange={(e) => setEmail(e.target.value)} required placeholder="Your email address" />
                                    {/* <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span> */}
                                </div>
                                <div hidden="true"><input type="hidden" name="tags" value={tagId} /></div>
                                <div id="mce-responses" class="clear foot">
                                    <div class="response" id="mce-error-response" style={{ display: "none" }}></div>
                                    <div class="response" id="mce-success-response" style={{ display: "none" }}></div>
                                </div>
                                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_5fd00cee0a74e2316d00a39ca_002ab63c42" tabindex="-1" value="" /></div>
                                <div class="optionalParent">
                                    <div class="clear foot">
                                        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
                                        <p class="brandingLogo"><a href="http://eepurl.com/ikEp0z" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" /></a></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                    {/* <input type="email" placeholder="Your email address" className={`email ${error ? 'red-border' : ''}`} name="email" value={form.email} pattern=".+@globex\.com" required={true} onChange={onUpdateField} />
                <button type="submit" className="button button-primary" onClick={() => result()} id="submit-btn-intent">{props.ctaLabel}</button> */}
                </div>
            </div>

        </div>
    )

}

export default ContestForm