import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'


import GetImgUrl from "../utils/customFunctions"

import { Helmet } from "react-helmet"

import "../styles/competitions.scss";

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";
import { GatsbyImage } from "gatsby-plugin-image"
import ContestForm from "../components/contest-form/contest-form"

// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Reviews = loadable(() => import("../components/reviews/reviews"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const Accordion = loadable(() => import( "../components/accordion/accordion"))
// const CTAList = loadable(() => import( "../components/cta-list/ctaList"))
// const Providers = loadable(() => import(  "../components/provider-list/providers"))
// const Comparison = loadable(() => import(  "../components/comparison/comparison"))
// const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
// const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))




export const query = graphql`
  query competitionPagesQuery($id: Int) {
    strapiCompetitions(strapiId: { eq: $id }) {
      Title
      Description
      Slug
      PageComponents
      SEOTitle
      SEODescription
      TagValue
      ImageForm {
        localFile{
            childImageSharp {
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(
                  width: 1900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
            }
        }
      }
      FormTitle
      FormSubtitle
      FormIcon{
        localFile{
          childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
              gatsbyImageData(
                width: 1900
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
          }
        }
      }
      AllowAnswer
      
    }
  }
`;






// markup
const Competition = ({ data }) => {

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    //   console.log(imgUrl)
    return (imgUrl)
  }



  if (data.strapiCompetitions.PageComponents) {

    return (

      <Layout SEOTitle={data.strapiCompetitions.SEOTitle || data.strapiCompetitions.Title} SEODescription={data.strapiCompetitions.SEODescription}>

        {/** List of meta properties coming from strapi that applies only for the primary pages */
          <Helmet>
            {data.strapiCompetitions.MetaTags?.map((item, index) => {
              return (
                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
              )
            })}
          </Helmet>
        }


        {data.strapiCompetitions.PageComponents.map((item, index) => {


          switch (item.strapi_component) {


            case "page-components.image":
              return (
                <ImageCarousel key={index}


                ></ImageCarousel>
              )



            case "page-components.header":
              return (

                <Header key={index}
                  title={item.Title}
                  markdownTitle={item.MarkdownTitle}
                  subtitle={item.Subtitle}
                  secondarySubtitle={item.SecondSubtitle}
                  CTAText={item.CTALabel}
                  CTAUrl={item.CTAUrl}
                  CTA_nofollow={item.CTA_nofollow}
                  Disclaimer={item.Disclaimer}
                  Background={GetImgUrl(item.Background?.localFile___NODE)}
                  HeaderHeight={item.HeaderHeight}
                ></Header>

              )

            case "page-components.cta-banner":
              return (

                <CTABanner
                  key={index}
                  text={item.Text}
                  ctaUrl={item.CTAUrl}
                  ctaLabel={item.CTALabel}
                  secondaryCtaUrl={item.SecondaryCTAUrl}
                  secondaryCtaLabel={item.SecondaryCTALabel}
                  bgColorHex={item.BackgroundColorHEX}
                ></CTABanner>

              )
            case "page-components.image-slider":
              var contentsList = []

              item.Picture.map((image, index) => {

                var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
                contentsList.push({
                  ALT: image.ALT,
                  Image: imageUrl,
                })
                return ("")
              })
              return (

                <ImageCarousel
                  key={index}
                  contents={contentsList}
                ></ImageCarousel>
              )

            case "page-components.comparison":
              return (

                <Comparison key={index}
                  title={item.Title}
                  step1={item.Step1}
                  step2={item.Step2}
                  step3={item.Step3}
                  image1={GetImgUrl(item.Image1.localFile___NODE)}
                  image2={GetImgUrl(item.Image2.localFile___NODE)}
                  image3={GetImgUrl(item.Image3.localFile___NODE)}
                ></Comparison>


              )


            case "page-components.banner":
              return (

                <Banner
                  key={index}
                  text={item.Text}
                  image={GetImgUrl(item.Image.localFile___NODE)}
                  imageAlt={item.Image.alternativeText}
                  dark={item.DarkBackground}
                  title={item.Title}
                  subtitle={item.Subtitle}
                ></Banner>


              )

            case "page-components.paragraph":
              return (

                <Paragraph
                  key={index}
                  title={item.Title ? item.Title : ""}
                  text={item.Text}
                  bgColor={item.BackgroundColor || ""}

                ></Paragraph>


              )


            case "page-components.cta-list":
              var contentsList = []
              item.CTAList.map((CTAitem, index) => {
                var imageUrl = CTAitem.Image !== null ? GetImgUrl(CTAitem.Image.localFile___NODE) : ""
                contentsList.push({
                  Title: CTAitem.Title,
                  URL: CTAitem.URL,
                  URLLabel: CTAitem.URLLabel,
                  Image: imageUrl,
                  Alt: CTAitem.Image.alternativeText,
                  Description: CTAitem.Description,
                  DescriptionCharLimit: CTAitem.DescriptionCharLimit
                })
                return ("")
              })
              return (

                <CTAList
                  key={index}
                  title={item.Title ? item.Title : ""}
                  subtitle={item.Subtitle}
                  contents={contentsList}
                  cardStyle={item.CardStyle}
                ></CTAList>


              )

            // case "page-components.html-editor":
            //     return(

            //             <HtmlEditor 
            //             key={index}
            //             htmlContent={item.HTML}
            //             fullWidth={item.FullWidth}
            //         ></HtmlEditor> 


            //     )

            default:
              return ("")
          }




        })}


        <div className="contest-form uk-child-width-1-2@s uk-child-width-1-1 uk-grid" uk-grid="true">
          <GatsbyImage className="form-image" image={data.strapiCompetitions.ImageForm?.localFile.childImageSharp.gatsbyImageData} alt=""></GatsbyImage>
          <ContestForm
            title={data.strapiCompetitions.FormTitle}
            subtitle={data.strapiCompetitions.FormSubtitle}
            icon={data.strapiCompetitions.FormIcon?.localFile.childImageSharp.gatsbyImageData}
            tagId={data.strapiCompetitions.TagValue}
            allowAnswer={data.strapiCompetitions.AllowAnswer} />
        </div>



      </Layout>
    )

  } else { return (<Layout>page empty. add some components.</Layout>) }

}

export default Competition